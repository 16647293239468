#why-choose {
  background-color: $white;

  .header {
    margin: 0 auto 30px;
    max-width: 775px;

    .h1 {
      margin-bottom: 15px;
      font-weight: 400;
    }

    p {
      font-weight: 500;
    }
  }

  .col-whychoose {
    border: 2px solid $brand-primary;
    height: 100%;
    margin: 0 auto;
    max-width: 362px;
  }

  .image-container {
    position: relative;

    img {
      border: 3px solid $brand-primary;
      object-fit: cover;
      width: 100%;
      height: 320px;
      display: block;
    }
  }

  span.triangle-cover {
    width: 100%;
    height: 16%;
    overflow: hidden;
    display: block;
    position: absolute;
    bottom: -2px;
  }

  span.triangle {
    width: 0;
    height: 0;
    border-bottom: 100px solid $brand-primary;
    border-right: 900px solid transparent;
    display: block;
    position: absolute;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 100px solid $white;
      border-right: 900px solid transparent;
      display: block;
      position: absolute;
      top: 5px;
    }
  }

  .content {
    padding: 11px 25px 30px;

    .h2 {
      margin-bottom: 30px;
    }

    p {
      font-size: 19px;
      line-height: 24px;
    }
  }
}

#services {
  padding-bottom: 30px;
  // background-image: url('../images/services-background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba($black, 0.4);
  background-blend-mode: overlay;

  .header {
    max-width: 877px;
    margin: 0 auto;

    .h1 {
      margin-bottom: 16px;
    }

    .h1,
    p {
      color: $white;
    }
  }

  a {
    font-weight: 700;
    text-decoration: underline;
    font-size: 19px;

    &:hover {
      color: $brand-primary;
    }
  }

  .col-serve {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    border: 2px solid $brand-primary;
    overflow: hidden;
    margin-bottom: 30px;

    @include tablet {
      flex-flow: row;
    }

    .img-cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      min-height: 250px;
      display: block;
      border: 2px solid $brand-primary;
      flex-shrink: 0;

      @include phablet {
        min-height: 211px;
      }

      @include tablet {
        min-height: 250px;
        width: 375px;
      }

      @include desktop {
        width: 270px;
      }

      @include wide {
        width: 370px;
      }
    }

    .col-serve-txt {
      margin-left: auto;
      position: relative;
      padding: 30px;
      background-color: transparent;

      @include desktop {
        padding: 30px 27px 30px 0;
      }

      .text {
        position: relative;
        z-index: 1;
      }

      p {
        margin-bottom: 15px;
        max-width: 500px;
        font-size: 19px;
        line-height: 1.2;

        @include desktop {
          max-width: 350px;
        }
      }

      &::before {
        display: block;
        content: '';
        background-color: $white;
        position: absolute;
        height: 100%;
        width: 200%;
        top: -5px;
        left: -46px;
        border-top: 4px solid $brand-primary;
        z-index: 0;
        transform: rotate(6deg);

        @include tablet {
          top: 0;
          width: 90%;
          left: -46px;
          border-top: transparent;
          border-left: 4px solid $brand-primary;
          transform: skew(-10deg);
        }
      }
    }
  }
}

#blog-section {
  background: $white;

  .blog-items {
    margin-bottom: 20px;
  }

  .header {    
    .h1 {
      margin-bottom: 15px;
      font-weight: 400;
    }
  }

  .blog-col:not(:last-child) {
      margin-bottom: 26px;

      @include desktop {
        margin-bottom: 0
      }
  }

  .blog-card {
    border: 3px solid #ee3229;
    padding: 15px;
    background: $white;

    .h2 {

      @include links-transition;
    }

    .h2:hover {
      color: #ee3229;
    }

    .blog-text {
      font-size: 19px;
      line-height: 1.2;
    }
  }
}

#locations {
  min-height: 565px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0 0 -69px;

  .locations-bg {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: 0;
  }

  .locations-map {
    iframe {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: -69px;
      border: 0;
    }

    .i4ewOd-pzNkMb-haAclf {
      display: none;
    }
  }

  .locations-container {
    height: 565px;

    .locations-header {
      h2 {
        font-weight: 400;
        text-shadow: 1px 1px #8b8b8b;
      }
    }
  }
}