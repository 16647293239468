.hero-panel,
.prefooter-panel {
  position: relative;
  background-color: #4f4f4f;

  .container {
    position: relative;
    z-index: 1;
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;

  @include tablet {
    height: 400px;
  }

  @include laptop {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  display: none;

  @include laptop {
    display: block;
  }
}

.hero-panel .hero-content {
  max-width: 650px;

  @include wide {
    padding: 170px 0 200px;
  }

  @include hd {
    padding: 190px 0 260px;
  }
}

.prefooter-panel .hero-content {
  max-width: 720px;

  @include wide {
    padding: 220px 0 185px;
  }
}

.hero-panel .interior-hero-content {
  @include laptop {
    padding: 130px 0 90px;
  }
}

.hero-content {
  padding: 22px 0;

  @include tablet {
    padding: 35px 0;
  }

  @include laptop {
    padding: 140px 0 120px;
  }

  .line1,
  .line2 {
    text-shadow: 1px 1px $black;
    color: $white;
    font-weight: 700;
  }

  .line1 {
    font-size: 34px;
    line-height: 40px;

    @include tablet {
      font-size: 50px;
      line-height: 60px;
    }
  }

  .line2 {
    font-size: 21px;
    line-height: 30px;

    @include tablet {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .cta-container {
    margin-top: 23px;
  }

  .btn {
    margin-bottom: 10px;

    &:not(:first-of-type) {
      @include tablet {
        margin-left: 20px;
      }
    }
  }
}

.strapline {
  background: rgba(50, 50, 50, 0.9);
  text-align: center;
  position: relative;
  padding: 22px 0;

  @include tablet {
    padding: 35px 0;
  }

  @include wide {
    padding: 55px 0;
  }

  p {
    color: $white;
    font-size: 22px;
    margin: 0 auto;
    max-width: 1110px;

    @include laptop {
      font-size: 25px;
    }
  }
}

.overlay::after {
  @include laptop {
    content: '';
    background: linear-gradient(0.25turn, rgba($white, 0.7) 46%, rgba($white, 0) 63%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
