.card_block {
  background-color: $white;
  border: 2px solid $blue;
  padding: 15px;
}

.widget_block {
  @extend .card_block;
  @extend .mb-4;

  a.wp-block-button__link {
    @extend .btn-secondary;
    @extend .btn;
  }
}
