a[href^="tel:"] {
  color: $body-color;
  text-decoration: none;
}

a[target="_blank"]::after {
  content: '\f08e';
  font-family: 'Font Awesome 6 Pro', sans-serif;
  margin-left: 7px;
}

.no-external-link,
a[href^="mailto:"] {
  &::after {
    content: '' !important;
  }
}

a[href$=".pdf"]::after {
  content: '\f1c1';
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;

    @include laptop {
      min-height: 300px;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.page-numbers {
  text-decoration: none;
  margin-right: 5px;
}

.page-header h1 {
  margin: 42px 0 24px;
}

.product-row {
  margin-bottom: 30px;

  a.service-anchor {
    display: block;
    font-weight: 700;
    text-decoration: underline;
    font-size: 19px;
    z-index: 9999;

    &::after {
      font-size: 16px;
      margin-left: 5px;
    }

    &:hover {
      color: $brand-primary;
    }
  }

  p.service-text-anchor {
    z-index: 9999;
    position: relative;
  }

  p.staff-text-anchor {
    z-index: 9999;
    position: relative;
    margin-bottom: 0;
  }

  .col-product {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    border: 2px solid $brand-primary;
    overflow: hidden;
    margin-bottom: 30px;

    @include tablet {
      flex-flow: row;
    }

    .img-cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      min-height: 250px;
      display: block;
      border: 2px solid $brand-primary;

      @include phablet {
        min-height: 211px;
      }

      @include tablet {
        width: 45%;
      }
    }

    .col-product-txt {
      margin-left: auto;
      position: relative;
      padding: 30px;
      background-color: transparent;

      @include tablet {
        width: 55%;
      }

      @include desktop {
        padding: 30px 27px 30px 0;
        min-height: 320px;
      }

      .h2,
      span.h3-subtext,
      a.service-anchor {
        z-index: 999999;
        position: relative;
        margin-top: 0;
      }

      .h2 {
        margin-bottom: 30px;
      }

      span.h3-subtext {
        display: block;
        margin-bottom: 15px;
      }

      &::before {
        display: block;
        content: " ";
        background-color: #fff;
        position: absolute;
        height: 100%;
        width: 200%;
        top: -5px;
        left: -46px;
        border-top: 4px solid $brand-primary;
        z-index: 0;
        transform: rotate(6deg);

        @include tablet {
          top: 0;
          width: 90%;
          left: -46px;
          border-top: transparent;
          border-left: 4px solid $brand-primary;
          transform: skew(-10deg);
        }
      }
    }
  }
}

.staff-group-title {
  font-size: 24px;
  color: #000;
  font-family: $primary-font;
  font-weight: 700;
  line-height: 1.1;
}

.products-three-col {
  .col-product {
    flex-direction: column;

    .img-cover,
    .col-product-txt {
      width: 100%;
    }

    .col-product-txt {
      min-height: 0;
      padding: 5px 10px 10px 10px;

      @include phablet {
        min-height: 76px;
        padding-bottom: 21px;
      }

      &::before {
        left: 0;
        right: 0;
        top: -30px;
        border-left: transparent;
        border-top: 4px solid $brand-primary;
        background-color: #fff;
        transform: skew(0deg, 5deg);
        width: 100%;
        height: 300px;

        @include phablet {
          top: -15px;
        }
      }
    }
  }
  
}

.staff-col-12 {
  .col-product {
    .img-cover {
      object-fit: cover;
      min-height: fit-content;

      @include mobile {
        height: 183px;
      }

      @include tablet {
        height: 237px;
      }

      @include laptop {
        height: 327px;
      }

      @include desktop {
        height: 233px;
      }

      @include wide {
        height: 280px;
      }
      
      @include hd {
        height: 316px;
      }
    }

    .col-product-txt {
      display: none;

      &::before {
        display: none;
    }
  }
}
}

body.page-template-template-forms {
  background-color: $white;
}
