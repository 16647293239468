.btn {
  padding: 10px 20px 12px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 0;
  width: 100%;
  text-decoration: none;

  @include tablet {
    width: auto;
  }

  @include laptop {
    padding: 13px 30px 17px;
    font-size: 23px;
    line-height: 23px;
  }
}

.btn-primary {
  color: $white;
}

.search-submit {
  padding: 12px 30px 13px;
  width: auto;

  @extend .btn;
  @extend .btn-primary;
}
