section#testimonials {
  background-color: $white;
  padding-bottom: 30px;

  .h1 {
    font-weight: 400;
    max-width: 300px;
    margin: 0 auto 10px;

    @include tablet {
      max-width: none;
    }
  }

  .slick-slide {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include mobile {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .slick-single {
    .slick-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .quote {
      text-align: center;
      margin: 0 auto;
      border: solid 8px $brand-primary;
      padding: 15px;
      position: relative;

      @include laptop {
        max-width: 390px;
        text-align: left;
        padding-left: 80px;
      }

      @include desktop {
        max-width: 470px;
      }

      img.img-quote {
        height: 87px;
        position: absolute;
        left: -20px;
        top: -20px;
        display: none;

        @include laptop {
          display: block;
          width: auto;
          margin-bottom: 30px;
        }
      }

      .quote-author {
        display: block;
        font-weight: 700;
        text-align: center;
        margin-top: 15px;

        @include laptop {
          text-align: right;
          margin-top: 0;
        }
      }

      p {
        line-height: 22px;
      }
    }
  }

  .slick-prev,
  .slick-next {
    height: 30px;

    &::before {
      color: $brand-primary;
      font-family: 'Font Awesome 6 Pro', sans-serif;
      content: "\f053";
      font-size: 30px;
      font-weight: 700;
    }
  }

  .slick-prev {
    @include desktop {
      left: -60px;
    }
  }

  .slick-next {
    @include desktop {
      right: -60px;
    }

    &::before {
      content: "\f054";
    }
  }

  .slick-dotted {
    padding-left: 0;
    padding-right: 0;
  }

  .slick-dots {
    left: 0;
    right: 0;

    button::before {
      font-size: 15px;
      color: $brand-primary;
    }
  }
}

footer.footer-panel {
  background-color: $white;
  position: relative;
  padding: 23px 0 7px;

  .brand-container {
    margin-bottom: 25px;
  }

  .brand {
    position: relative;
    display: inline-block;
    font-weight: 700;
    line-height: 33px;
    font-size: 14px;
    margin-left: -22px;

    @media (min-width: 375px) {
      margin-left: 0;
    }

    @include laptop {
      font-size: 16px;
    }

    @include wide {
      font-size: 17px;
    }

    .brand-before,
    .brand-after {
      height: 24px;
      bottom: 36px;

      @include tablet {
        height: 29px;
        bottom: 42px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  a,
  span {
    font-weight: 500;
  }

  .contact-info {
    margin-bottom: 25px;

    span {
      margin: 0 6px;
      display: block;

      @include tablet {
        display: inline;
      }
    }

    .footer-location:not(:first-child) {
      margin-top: 8px;
    }

    .footer-image-link {
      display: inline-block;
      margin: 12px auto;
      position: relative;
      width: 150px;
  
      @include links-transition;
  
      @include mobile {
        margin: 12px;
        display: inline-block;
      }
  
      @include laptop {
        margin: 0 12px;
      }
    }
  
    .footer-image-links a {
      position: relative;
    }
  
    .footer-image-links a::after {
      display: none;
    }
  
    .footer-image-link-border {
      border: 3px solid $brand-primary;
      height: 104%;
      width: 102%;
      position: absolute;
      left: -8px;
      top: -1.5px; 
      opacity: 0;

      @include links-transition;
    }
  
    .footer-image-link:hover {
      .footer-image-link-border {
        display: inline-block;
        opacity: 1;
  
        @include links-transition;
      }
    }
  }

  .nav {
    .sub-menu {
      display: none;
    }

    li:first-of-type {
      margin-left: 0;
    }

    li:last-of-type {
      margin-right: 0;
    }

    li {
      margin: 10px 0;
      
      @include tablet {
        margin: 10px 2.2%;
      }

      @include desktop {
        margin: 0 2.2% 20px;
      }

      @include hd {
        margin: 0 3.5% 20px;
      }
    }
  }
}

footer.copyright {
  background-color: #f9f9f9;
  padding: 10px 0;

  *,
  a:hover {
    font-size: 12px;
    text-decoration: none;
    color: $black;
  }

  a::after {
    display: none;
  }
}
