#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.brand {
  font-family: $secondary-font;
  width: 345px;
  z-index: 1;

  @include tablet {
    width: 426px;
  }

  img {
    width: 400px;
  }

  .brand-before,
  .brand-after {
    background-size: contain;
    position: absolute;
    height: 23px;
    bottom: 37px;
    width: 100%;

    @include tablet {
      height: 29px;
      bottom: 45px;
    }
  }

  .brand-before {
    right: 300px;

    @include tablet {
      right: 350px;
      left: -9914px;
      right: 350px;
      width: 10000px;
      z-index: -1;
    }
  }

  .brand-after {
    right: -10000px;
    left: 300px;
    width: 100vw;

    @include tablet {
      left: 350px;
    }
  }

  &:hover {
    color: $black;
  }
}

.banner .brand {
  position: absolute;
  left: 0;
  bottom: -50px;
  font-weight: 700;
  line-height: 33px;
  margin-left: 0 !important;

  @include tablet {
    bottom: -69px;
  }

  @include hd {
    left: auto;
  }
}

.banner {
  background-color: $white;
  position: relative;
  z-index: 2;
  padding: 0 0 11px;

  @include tablet {
    padding: 11px 0;
  }

  .nav-primary > li:not(:first-of-type) {
    @include wide {
      margin-left: 7px;
    }

    @include hd {
      margin-left: 20px;
    }
  }

  // a[aria-current="page"] {
  //   color: darken($brand-primary, 7%);
  // }

  a {
    text-decoration: none;
    padding: 4px 10px;
    font-size: 14px;

    @include laptop {
      font-size: 16px;
    }

    @include wide {
      font-size: 17px;
    }
  }

  .phone {
    background-color: $blue;
    color: $white;
    font-size: 20px;
    font-weight: 700;
    display: block;

    @include tablet {
      font-size: 17px;
    }

    @include laptop {
      display: block;
    }
  }
}

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  padding: 0;
  z-index: 1;

  @include links-transition((visibility, opacity, top));

  li {
    @include links-transition();
  }
}

.banner .sub-menu {
  background-color: $white;
  border: 1px solid gray;
  position: absolute;
  max-width: 320px;
  min-width: 180px;
  margin-left: 10px;
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 5px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }
}
